import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrl: './title.component.scss'
})
export class TitleComponent {

    constructor(
        public dialog: MatDialog
    ) { }

    showInfo() {
        this.dialog.open(InfoDialogComponent);
    }

}
