<div class="payment-request-content">
    <app-header [displayBackButton]="displayBackButton" (back)="back()"></app-header>

    <main class="payment-request-main">
        <app-title />

        <div class="label-and-value">
            <label>Kedvezményezett neve:</label>
            <p class="emphasized">{{ initialData.payee.name }}</p>
        </div>

        <div class="label-and-value">
            <label>Kedvezményezett számlaszáma:</label>
            <p>{{ initialData.payee.accountNumber }}</p>
        </div>

        <div class="label-and-value">
            <label>Fizetési határidő:</label>
            <p>{{ initialData.deadline | date:'yyyy.MM.dd. HH:mm' }}</p>
        </div>

        <div class="label-and-value">
            <label>Lejárati idő:</label>
            <p>{{ initialData.validityDate| date:'yyyy.MM.dd.' }}</p>
        </div>

        <form [formGroup]="dataInputForm">

            <div *ngIf="initialData.amountModifiable;else displayAmount" class="label-and-value">
                <label>Összeg (Ft):</label>
                <mat-form-field>
                    <mat-icon class="material-icons-outlined" matPrefix>payments</mat-icon>
                    <input matInput type="number" placeholder="Összeg" formControlName="amount">
                    <mat-error *ngIf="dataInputForm.get('amount')?.errors?.['required']">
                        Az összeg kitöltése kötelező.
                    </mat-error>
                    <mat-error *ngIf="dataInputForm.get('amount')?.errors?.['max']">
                        A maximális összeg {{initialData.maxAmount}}Ft.
                    </mat-error>
                </mat-form-field>
            </div>
            <ng-template #displayAmount>
                <div class="label-and-value">
                    <label>Összeg (Ft):</label>
                    <p>{{ initialData.amount }}</p>
                </div>
            </ng-template>

            <div *ngIf="initialData.commentModifiable;else displayComment" class="label-and-value">
                <label>Közlemény:</label>
                <mat-form-field>
                    <mat-icon class="material-icons" matPrefix>chat</mat-icon>
                    <input matInput placeholder="Közlemény" maxlength="140" formControlName="comment">
                </mat-form-field>
            </div>
            <ng-template #displayComment>
                <div class="label-and-value">
                    <label>Közlemény:</label>
                    <p>{{ initialData.comment ?? '-' }}</p>
                </div>
            </ng-template>

            <div *ngIf="initialData.payerModifiable; else displayPayer" formGroupName="payer">
                <div class="label-and-value">
                    <label>Számlatulajdonos neve:</label>
                    <mat-form-field>
                        <mat-icon class="material-icons-outlined" matPrefix>person</mat-icon>
                        <input matInput placeholder="Számlatulajdonos neve" formControlName="name">
                        <mat-error *ngIf="dataInputForm.get('payer.name')?.errors?.['required']">
                            A számlatulajdonos kitöltése kötelező.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="label-and-value">
                    <label>Számlaszám:</label>
                    <mat-form-field>
                        <mat-icon class="material-icons-outlined" matPrefix>account_balance</mat-icon>
                        <input matInput placeholder="Számlaszám" formControlName="accountNumber"
                            [errorStateMatcher]="accountNumberErrorStateMatcher">
                        <mat-hint *ngIf="bankForAccountNumberLoading">Ellenőrzés...</mat-hint>
                        <mat-hint *ngIf="!bankForAccountNumberLoading && bankForAccountNumber">
                            <mat-icon class="material-icons bank-check">check_circle</mat-icon>
                            <span>{{ bankForAccountNumber.name }}</span>
                        </mat-hint>
                        <mat-error *ngIf="dataInputForm.get('payer.accountNumber')?.errors?.['required']">
                            A számlaszám kitöltése kötelező.
                        </mat-error>
                        <mat-error *ngIf="dataInputForm.get('payer.accountNumber')?.errors?.['invalidAccountNumber']">
                            Érvénytelen számlaszám.
                        </mat-error>
                        <mat-error
                            *ngIf="dataInputForm.get('payer.accountNumber')?.errors?.['paymentRequestNotSupported']">
                            <mat-icon class="material-icons bank-check">cancel</mat-icon>
                            <span>{{ bankForAccountNumber?.name }} - a pénzintézet nem támogatja fizetési kérelmek
                                fogadását.</span>
                        </mat-error>
                        <mat-error *ngIf="dataInputForm.get('payer.accountNumber')?.errors?.['bankNotFound']">
                            Ismeretlen pénzintézet.
                        </mat-error>
                        <mat-error *ngIf="dataInputForm.get('payer.accountNumber')?.errors?.['unexpectedError']">
                            A számlaszám ellenőrzése sikertelen.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <ng-template #displayPayer>
                <div class="label-and-value">
                    <label>Számlatulajdonos neve:</label>
                    <p>{{ initialData.payer?.name }}</p>
                </div>

                <div class="label-and-value">
                    <label>Számlaszám:</label>
                    <p>{{ initialData.payer?.accountNumber }}</p>
                </div>
            </ng-template>

        </form>

    </main>

    <footer>
        <div class="payment-request-container">
            <div class="button-container">
                <button class="payment-request-button default" type="submit"
                    [disabled]="dataInputForm.invalid || bankForAccountNumberLoading" (click)="submit()">Tovább</button>
            </div>
            <div class="payment-request-break payment-request-separator"></div>
        </div>
    </footer>
</div>