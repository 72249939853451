<div class="payment-request-content">
    <app-header [displayBackButton]="false"></app-header>

    <main class="payment-request-main payment-request-result">
        <app-title />

        <div class="result-icon-container">
            <mat-icon class="material-icons error-result-icon" matPrefix>error_outline</mat-icon>
        </div>

        <h1>Hiba történt</h1>

        <p *ngIf="errorType === 'NO_TOKEN'">Nem található fizetési kezdeményezés. Kérjük kezdd újra a folyamatot.</p>
        <p *ngIf="errorType === 'INVALID_REDIRECT_URL'">Érvénytelen átirányítás.</p>
        <p *ngIf="errorType === 'EXPIRED'">A fizetési kérelem érvényességi ideje lejárt.</p>
        <p *ngIf="errorType === 'ALREADY_CONFIRMED'">A fizetési kérelem már megerősítésre került.</p>
        <p *ngIf="errorType === 'UNEXPECTED' || !errorType">Váratlan hiba történt.</p>

    </main>
</div>