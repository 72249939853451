/**
 * sw-pr-webapp-be
 * SpendWyze Payment Request Webapp Backend.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PaymentRequestTaskAdditionalDataPayer } from './paymentRequestTaskAdditionalDataPayer';

export interface PaymentRequestTaskAdditionalData { 
    payer?: PaymentRequestTaskAdditionalDataPayer;
    /**
     * The amount of the transaction.
     */
    amount?: number;
    /**
     * The comment of the transaction.
     */
    comment?: string;
}