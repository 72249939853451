export * from './bank.service';
import { BankService } from './bank.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './logo.service';
import { LogoService } from './logo.service';
export * from './paymentRequestTask.service';
import { PaymentRequestTaskService } from './paymentRequestTask.service';
export * from './swagger.service';
import { SwaggerService } from './swagger.service';
export const APIS = [BankService, HealthService, LogoService, PaymentRequestTaskService, SwaggerService];
