import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppStateService } from '../service/app-state.service';

export const dataInputGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const appStateService = inject(AppStateService);
    await appStateService.loadDataFromBackend();

    if (!appStateService.dataInputPossible) {
        return router.createUrlTree(['confirmation']);
    }
    return true;
};

export const confirmationGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const appStateService = inject(AppStateService);
    await appStateService.loadDataFromBackend();

    if (appStateService.dataInputPossible && !appStateService.dataFromUser) {
        return router.createUrlTree(['data-input']);
    }
    return true;
};

export const resultGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const appStateService = inject(AppStateService);
    await appStateService.loadDataFromBackend();

    if (!appStateService.result) {
        return router.createUrlTree(['confirmation']);
    }
    return true;
};
