<div class="payment-request-content">
    <app-header [displayBackButton]="false"></app-header>

    <main class="payment-request-main payment-request-result">
        <app-title />

        <div class="result-icon-container">
            <mat-icon *ngIf="result === 'SUCCESSFUL'" class="material-icons success-result-icon"
                matPrefix>check_circle_outline</mat-icon>
            <mat-icon *ngIf="result === 'UNSUCCESSFUL'" class="material-icons error-result-icon"
                matPrefix>error_outline</mat-icon>
        </div>

        <h1 *ngIf="result === 'SUCCESSFUL'">Sikeres Kezdeményezés!</h1>
        <h1 *ngIf="result === 'UNSUCCESSFUL'">Sikertelen Kezdeményezés!</h1>

        <p *ngIf="result === 'SUCCESSFUL'">A Fizetési kérelmet sikeresen elindítottad!<br />
            Nyisd meg a netbankodat a fizetés engedélyezéséhez.</p>
        <p *ngIf="result === 'UNSUCCESSFUL'">A Fizetési kérelem beküldése sikertelen volt, mivel megszakítottad a
            folyamatot az adatok ellenőrzése közben.<br />
            A Fizetési kérelmet az eredeti linkre kattintva újból megkísérelheted beküldeni.</p>

        <p *ngIf="redirectUrl; else elseBlock">{{counter}} másodperc múlva automatikusan visszairányítunk a kereskedőhöz.</p>
        <ng-template #elseBlock><p>Ezt az ablakot bezárhatod.</p></ng-template>

        <div *ngIf="redirectUrl" class="result-button-container">
            <button class="payment-request-button default" (click)="redirect()">Vissza a Kereskedőhöz</button>
        </div>
    </main>
</div>