/**
 * sw-pr-webapp-be
 * SpendWyze Payment Request Webapp Backend.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Bank { 
    /**
     * The display name of the Bank
     */
    name: string;
    /**
     * Indicates if the Bank supports receiving Payment Requests.
     */
    paymentRequestSupported: boolean;
}