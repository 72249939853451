import { Component } from '@angular/core';

@Component({
    selector: 'app-info-icon',
    templateUrl: './info-icon.component.html',
    styleUrl: './info-icon.component.scss'
})
export class InfoIconComponent {

}
