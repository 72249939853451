import { Component } from '@angular/core';
import { AppErrorType, AppStateService } from '../0-shared/service/app-state.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrl: './error.component.scss'
})
export class ErrorComponent {

    errorType: AppErrorType | null = null;

    constructor(
        private appStateService: AppStateService
    ) { }

    async ngOnInit() {
        this.errorType = this.appStateService.getErrorType();
    }

}
