import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AppError } from '../service/app-state.service';
import { ErrorDetails } from '../_generated/backend';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    const errorDetails = err.error as ErrorDetails | undefined;
                    switch (errorDetails?.message) {
                        case 'ALREADY_CONFIRMED':
                            return throwError(() => new AppError('ALREADY_CONFIRMED'));
                        case 'EXPIRED':
                            return throwError(() => new AppError('EXPIRED'));
                        default: // rethrow the HttpErrorResponse
                    }
                }

                return throwError(() => err);
            })
        );
    }
}
