import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppStateService, ResultType } from '../0-shared/service/app-state.service';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrl: './result.component.scss'
})
export class ResultComponent implements OnInit, OnDestroy {

    result?: ResultType;

    redirectUrl?: string;
    counter = 10;
    interval?: number;

    constructor(
        private appStateService: AppStateService
    ) { }

    ngOnInit() {
        this.result = this.appStateService.result;

        const redirectUrl = this.appStateService.getRedirectUrl();

        if (redirectUrl) {
            this.redirectUrl = redirectUrl;
            this.interval = setInterval(() => {
                this.counter -= 1;
                if (this.counter === 0) {
                    clearInterval(this.interval);
                    this.redirect();
                }
            }, 1000);
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    redirect() {
        window.location.href = this.redirectUrl!;
    }

}
