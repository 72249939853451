import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatOptionModule, provideNativeDateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, BASE_PATH, Configuration } from './0-shared/_generated/backend';
import { DataInputComponent } from './data-input/data-input.component';
import { ErrorComponent } from './error/error.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { InitComponent } from './init/init.component';
import { ErrorInterceptor } from './0-shared/interceptor/error.interceptor';
import { GlobalErrorHandler } from './0-shared/error-handler/global.error-handler';
import { HeaderComponent } from './ui-components/header/header.component';
import { SpendWyzeLogoComponent } from './ui-components/spendwyze-logo/spendwyze-logo.component';
import { LeftArrowIconComponent } from './ui-components/left-arrow-icon/left-arrow-icon.component';
import { ResultComponent } from './result/result.component';
import { InfoDialogComponent } from './ui-components/info-dialog/info-dialog.component';
import { TitleComponent } from './ui-components/title/title.component';
import { InfoIconComponent } from './ui-components/info-icon/info-icon.component';

registerLocaleData(localeHu);

@NgModule({
    declarations: [
        AppComponent,
        DataInputComponent,
        ErrorComponent,
        ConfirmationComponent,
        InitComponent,
        HeaderComponent,
        SpendWyzeLogoComponent,
        LeftArrowIconComponent,
        ResultComponent,
        InfoDialogComponent,
        TitleComponent,
        InfoIconComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatOptionModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatToolbarModule,
        MatToolbarModule,
        MatTooltipModule,
        NoopAnimationsModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => new Configuration({ apiKeys: {} }))
    ],
    providers: [
        provideNativeDateAdapter(),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: MatSnackBarRef, useValue: {} },
        { provide: MAT_SNACK_BAR_DATA, useValue: {} },
        { provide: BASE_PATH, useFactory: () => environment.BACKEND_URL },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        },
        FormsModule,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: MatSnackBarRef,
            useValue: {}
        }, {
            provide: MAT_SNACK_BAR_DATA,
            useValue: {}
        },
        {
            provide: LOCALE_ID,
            useValue: 'hu-HU'
        },
        provideAnimationsAsync(),
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                width: '400px',
                backdropClass: 'dialog-backdrop',
                enterAnimationDuration: 100,
                exitAnimationDuration: 100
            } satisfies MatDialogConfig
        }

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
