/**
 * sw-pr-webapp-be
 * SpendWyze Payment Request Webapp Backend.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PaymentRequestTaskInitialDataPayer { 
    /**
     * The name of the transaction's payer.
     */
    name?: string;
    /**
     * The account number of the transaction's payer.
     */
    accountNumber?: string;
}