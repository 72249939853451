<mat-dialog-content>
  <div class="dialog-icon-container">
    <app-info-icon />
  </div>
  <h1>Fizetési Kérelem Kezdeményezése</h1>
  <p>A Fizetési Kérelem egy olyan fizetési lehetőség, amivel a Kezdeményezett fél (vagy annak meghatalmazottja)
    egyszerűen be tudja kérni a FIzető féltől azt a pénzösszeget, amit utána a Fizető fél egy egyszerű mobilbanki
    jóváhagyással ki is tud egyenlíteni.</p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="button-container">
    <button class="payment-request-button default" mat-dialog-close>Bezárás</button>
  </div>
</mat-dialog-actions>