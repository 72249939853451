import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppStateService } from '../../0-shared/service/app-state.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

    @Input({ required: true }) displayBackButton!: boolean;

    @Output() back: EventEmitter<void> = new EventEmitter();

    logoResourceUrl?: SafeResourceUrl;

    constructor(
        private appStateService: AppStateService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        const logo = this.appStateService.logo;
        if (logo) {
            this.logoResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(logo.dataUri);
        }
    }

    onBack() {
        this.back.emit();
    }

}
