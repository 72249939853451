import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppError, AppStateService } from '../service/app-state.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private router: Router,
        private appStateService: AppStateService,
        private ngZone: NgZone
    ) { }

    handleError(error: unknown) {
        if (error instanceof AppError) {
            console.warn('Application error', error.type, error.message);
            this.appStateService.setErrorType(error.type);
        } else {
            console.error('Unexpected error', error);
            this.appStateService.setErrorType('UNEXPECTED');
        }
        this.ngZone.run(() => this.router.navigate(['error']));
    }
}
