import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Configuration, PaymentRequestTaskInitialData, PaymentRequestTaskService, Logo, LogoService, PaymentRequestTaskAdditionalData } from '../_generated/backend';

export type ResultType = 'SUCCESSFUL' | 'UNSUCCESSFUL';
export type AppErrorType = 'NO_TOKEN' | 'INVALID_REDIRECT_URL' | 'UNEXPECTED' | 'EXPIRED' | 'ALREADY_CONFIRMED';

export class AppError extends Error {
    constructor(
        public readonly type: AppErrorType,
        message?: string
    ) {
        super(message);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AppStateService {

    dataLoaded = false;

    initialData?: PaymentRequestTaskInitialData;
    logo?: Logo;
    dataInputPossible = false;
    dataFromUser?: PaymentRequestTaskAdditionalData;

    result?: ResultType;

    constructor(
        private paymentRequestTaskService: PaymentRequestTaskService,
        private logoService: LogoService,
        private backendConfiguration: Configuration
    ) { }

    setToken(token?: string) {
        if (token) {
            sessionStorage.setItem('token', token);
        }
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    setRedirectUrl(redirectUrl?: string) {
        if (redirectUrl) {
            sessionStorage.setItem('redirectUrl', redirectUrl);
        }
    }

    getRedirectUrl() {
        return sessionStorage.getItem('redirectUrl');
    }

    setErrorType(errorType: AppErrorType) {
        sessionStorage.setItem('errorType', errorType);
    }

    getErrorType() {
        return sessionStorage.getItem('errorType') as AppErrorType | null;
    }

    reset() {
        sessionStorage.clear();
        this.backendConfiguration.accessToken = undefined;
        this.dataLoaded = false;
        this.initialData = undefined;
        this.logo = undefined;
        this.dataInputPossible = false;
        this.dataFromUser = undefined;
        this.result = undefined;
    }

    async loadDataFromBackend() {
        if (this.dataLoaded) {
            return;
        }

        const token = this.getToken();
        const redirectUrl = this.getRedirectUrl();

        if (!token) {
            throw new AppError('NO_TOKEN');
        }

        // every backend call will use this token from this point
        this.backendConfiguration.accessToken = token;

        if (redirectUrl) {
            const validationResult = await firstValueFrom(this.paymentRequestTaskService.validateRedirectUrl(redirectUrl));
            if (!validationResult.valid) {
                throw new AppError('INVALID_REDIRECT_URL');
            }
        }

        this.initialData = await firstValueFrom(this.paymentRequestTaskService.getTaskInitialData());
        this.logo = await firstValueFrom(this.logoService.getInitiatorLogo(this.initialData.initiatorId));

        this.dataInputPossible = this.initialData.amountModifiable || this.initialData.commentModifiable || this.initialData.payerModifiable;
        if (!this.dataInputPossible) {
            this.dataFromUser = {};
        }
        this.dataLoaded = true;
    }
}
