<div class="payment-request-content">
    <app-header [displayBackButton]="displayBackButton" (back)="back()"></app-header>

    <main class="payment-request-main">
        <app-title />

        <div class="label-and-value">
            <label>Kedvezményezett neve:</label>
            <p class="emphasized">{{ confirmationData.payee.name }}</p>
        </div>

        <div class="label-and-value">
            <label>Kedvezményezett számlaszáma:</label>
            <p>{{ confirmationData.payee.accountNumber }}</p>
        </div>

        <div class="label-and-value">
            <label>Fizetési határdő:</label>
            <p>{{ confirmationData.deadline | date:'yyyy.MM.dd. HH:mm' }}</p>
        </div>

        <div class="label-and-value">
            <label>Lejárati idő:</label>
            <p>{{ confirmationData.validityDate| date:'yyyy.MM.dd.' }}</p>
        </div>

        <div class="label-and-value">
            <label>Összeg (Ft):</label>
            <p>{{ confirmationData.amount }}</p>
        </div>


        <div class="label-and-value">
            <label>Közlemény:</label>
            <p>{{ confirmationData.comment ?? '-' }}</p>
        </div>

        <div class="label-and-value">
            <label>Számlatulajdonos neve:</label>
            <p>{{ confirmationData.payer?.name }}</p>
        </div>

        <div class="label-and-value">
            <label>Számlaszám:</label>
            <p>{{ confirmationData.payer?.accountNumber }}</p>
        </div>

    </main>

    <footer>
        <div class="payment-request-container">
            <div class="button-container">
                <button class="payment-request-button default" (click)="submit()">Fizetés indítása</button>
            </div>
            <div class="payment-request-break payment-request-separator"></div>
        </div>
    </footer>
</div>