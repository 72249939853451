import { Component, OnInit } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, delay, finalize, firstValueFrom, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';
import { PaymentRequestTaskService, BankService, PaymentRequestTaskInitialData, Bank, ErrorDetails, PaymentRequestTaskAdditionalData } from '../0-shared/_generated/backend';
import { AppError, AppStateService } from '../0-shared/service/app-state.service';

export class AccountNumberErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null): boolean {
        return !!(control && control.invalid && (control.dirty || control.touched));
    }
}

@Component({
    selector: 'app-data-input',
    templateUrl: './data-input.component.html',
    styleUrl: './data-input.component.scss'
})
export class DataInputComponent implements OnInit {

    initialData!: PaymentRequestTaskInitialData;

    dataInputForm!: FormGroup;
    accountNumberErrorStateMatcher = new AccountNumberErrorStateMatcher();

    bankForAccountNumberLoading = false;
    bankForAccountNumber?: Bank;

    displayBackButton = false;

    constructor(
        private fb: FormBuilder,
        private appStateService: AppStateService,
        private paymentRequestTaskService: PaymentRequestTaskService,
        private bankService: BankService,
        private router: Router
    ) { }

    async ngOnInit() {
        this.dataInputForm = this.buildForm();
        this.initialData = this.appStateService.initialData!;
        this.displayBackButton = this.appStateService.getRedirectUrl() != null;
        await firstValueFrom(this.paymentRequestTaskService.startDataInputForTask());
    }

    accountNumberValidator: AsyncValidatorFn = (control) =>
        of(control.value).pipe(
            tap(() => this.bankForAccountNumberLoading = true),
            tap(() => this.bankForAccountNumber = undefined),
            delay(500),
            switchMap((accountNumber) => this.bankService.getBankByAccountNumber(accountNumber).pipe(
                tap(bank => this.bankForAccountNumber = bank),
                map(bank => bank.paymentRequestSupported ? null : { paymentRequestNotSupported: true }),
                catchError(async (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 400 || err.status === 404) {
                            const errorDetails = err.error as ErrorDetails | undefined;
                            if (errorDetails?.message === 'Invalid account number') {
                                return { invalidAccountNumber: true };
                            } else if (errorDetails?.message === 'Bank not found') {
                                return { bankNotFound: true };
                            }
                        }
                    }
                    console.error('Unexpected error', err);
                    return { unexpectedError: true };
                }))),
            finalize(() => this.bankForAccountNumberLoading = false));

    async submit() {
        const formValue = this.dataInputForm.getRawValue() as PaymentRequestTaskAdditionalData;
        if (formValue.comment === null || formValue.comment === '') {
            delete formValue.comment;
        }

        this.appStateService.dataFromUser = formValue;

        this.router.navigate(['confirmation']);
    }

    back() {
        this.appStateService.result = 'UNSUCCESSFUL';
        this.router.navigate(['result']);
    }

    private buildForm() {
        const { initialData, dataFromUser } = this.appStateService;
        if (!initialData) {
            throw new AppError('UNEXPECTED', 'No initial data?');
        }

        const formValues = dataFromUser ?? initialData;

        const controlConfigs: any = {};
        if (initialData.amountModifiable) {
            controlConfigs.amount = [formValues.amount, [Validators.required, Validators.max(initialData.maxAmount)]];
        }
        if (initialData.commentModifiable) {
            controlConfigs.comment = [formValues.comment];
        }
        if (initialData.payerModifiable) {
            controlConfigs.payer = this.fb.group({
                accountNumber: [formValues.payer?.accountNumber, {
                    validators: [Validators.required],
                    asyncValidators: [this.accountNumberValidator]
                }],
                name: [formValues.payer?.name, [Validators.required]]
            });
        }

        return this.fb.group(controlConfigs);
    }
}
