import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InitComponent } from './init/init.component';
import { DataInputComponent } from './data-input/data-input.component';
import { confirmationGuard, dataInputGuard, resultGuard } from './0-shared/guard/route.guard';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ErrorComponent } from './error/error.component';
import { ResultComponent } from './result/result.component';

const routes: Routes = [
    { path: 'init', component: InitComponent },
    { path: 'data-input', component: DataInputComponent, canActivate: [dataInputGuard] },
    { path: 'confirmation', component: ConfirmationComponent, canActivate: [confirmationGuard] },
    { path: 'result', component: ResultComponent, canActivate: [resultGuard] },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: 'data-input' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
