import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppError, AppStateService } from '../0-shared/service/app-state.service';

@Component({
    selector: 'app-init',
    templateUrl: './init.component.html',
    styleUrl: './init.component.scss'
})
export class InitComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appStateService: AppStateService
    ) { }

    async ngOnInit() {
        const token: string | undefined | string[] = this.route.snapshot.queryParams['token'];
        const redirectUrl: string | undefined | string[] = this.route.snapshot.queryParams['redirectUrl'];

        // query params can be repeated, we don't support this
        if (Array.isArray(token)) {
            throw new AppError('NO_TOKEN');
        }
        if (Array.isArray(redirectUrl)) {
            throw new AppError('INVALID_REDIRECT_URL');
        }

        this.appStateService.reset();
        this.appStateService.setToken(token);
        this.appStateService.setRedirectUrl(redirectUrl);
        await this.appStateService.loadDataFromBackend();

        if (this.appStateService.dataInputPossible) {
            this.router.navigate(['data-input']);
        } else {
            this.router.navigate(['confirmation']);
        }
    }

}
