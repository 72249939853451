import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { PaymentRequestTaskService, PaymentRequestTaskInitialData, PaymentRequestTaskAdditionalData } from '../0-shared/_generated/backend';
import { AppStateService, AppError } from '../0-shared/service/app-state.service';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent implements OnInit {
    confirmationData!: PaymentRequestTaskInitialData;

    displayBackButton = false;
    dataFromUser!: PaymentRequestTaskAdditionalData;

    constructor(
        private appStateService: AppStateService,
        private paymentRequestTaskService: PaymentRequestTaskService,
        private router: Router
    ) { }

    async ngOnInit() {
        this.confirmationData = this.buildConfirmationData();
        this.dataFromUser = this.appStateService.dataFromUser!;
        this.displayBackButton = this.appStateService.dataInputPossible || this.appStateService.getRedirectUrl() != null;
        await firstValueFrom(this.paymentRequestTaskService.startConfirmationForTask());
    }

    async submit() {
        await firstValueFrom(this.paymentRequestTaskService.confirmTask(this.dataFromUser));
        this.appStateService.result = 'SUCCESSFUL';
        this.router.navigate(['result']);
    }

    back() {
        if (this.appStateService.dataInputPossible) {
            this.router.navigate(['data-input']);
        } else {
            this.appStateService.result = 'UNSUCCESSFUL';
            this.router.navigate(['result']);
        }
    }

    private buildConfirmationData() {
        const { initialData, dataFromUser } = this.appStateService;
        if (!initialData || !dataFromUser) {
            throw new AppError('UNEXPECTED', 'No initial or additional data?');
        }

        const confirmationData = { ...initialData };
        if (initialData.payerModifiable) {
            confirmationData.payer = dataFromUser.payer;
        }
        if (initialData.amountModifiable) {
            confirmationData.amount = dataFromUser.amount;
        }
        if (initialData.commentModifiable) {
            confirmationData.comment = dataFromUser.comment;
        }
        return confirmationData;
    }

}
