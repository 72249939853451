import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environmentLoader } from './environments/environment-loader';
import { environment } from './environments/environment';

environmentLoader.then((env) => {

    environment.environment = env.environment;
    environment.BACKEND_URL = env.BACKEND_URL;

    console.log('environment', environment);

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
});
