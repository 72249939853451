<header class="payment-request">
    <div class="payment-request-container">
        <div class="back-button-container">
            <button *ngIf="displayBackButton" class="back-button" (click)="onBack()"><left-arrow-icon /><span>Vissza</span></button>
        </div>
    </div>
    <div class="payment-request-container">
        <div>
            <img *ngIf="logoResourceUrl" [src]="logoResourceUrl" class="initiator-logo" alt="missing_logo">
        </div>
        <div>
            <spendwyze-logo />
        </div>
    </div>
</header>